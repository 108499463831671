// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
// @import '~ngx-sharebuttons/themes/material/material-dark-theme';
// @import "~ngx-sharebuttons/themes/modern/modern-light-theme";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Worknigeria-primary: mat.define-palette(mat.$indigo-palette);
$Worknigeria-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Worknigeria-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$Worknigeria-theme: mat.define-light-theme(
  $Worknigeria-primary,
  $Worknigeria-accent,
  $Worknigeria-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($Worknigeria-theme);

/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,600;0,700;0,900;1,200;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html,
body {
  height: 100%;
}
// body{font-family: 'Source Sans Pro', sans-serif; font-style: normal; font-weight: normal; font-size: 14px; line-height: 16px;}
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
// .mat-tab-body-wrapper{ height: 76vh;}
[mat-align-tabs="center"] > .mat-tab-header .mat-tab-labels {
  justify-content: left !important;
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3eab70 !important;
}
.mat-tab-label-active {
  color: #3eab70 !important;
  opacity: 1 !important;
}
.mat-dialog-container {
  position: relative;
}
.close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 18px;
  color: #000000;
  z-index: 999999;
}
/*---20-05-21---*/

.btn-submit {
  letter-spacing: 1px;
}

/*---20-05-21---*/
.snackbar {
  background-color: none !important;
}
.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: transparent !important;
  box-shadow: 0 0 0 !important;
}
/* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";
