@charset "utf-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Volkhov:wght@100;300;400;500;700;900&display=swap');
/*..............embedded fonts..............*/
/*
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/HandoTrial-Light.eot');
    src: url('../assets/fonts/HandoTrial-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/HandoTrial-Light.woff2') format('woff2'),
        url('../assets/fonts/HandoTrial-Light.woff') format('woff'),
        url('../assets/fonts/HandoTrial-Light.ttf') format('truetype'),
        url('../assets/fonts/HandoTrial-Light.svg#HandoTrial-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/HandoTrial-Bold.eot');
    src: url('../assets/fonts/HandoTrial-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/HandoTrial-Bold.woff2') format('woff2'),
        url('../assets/fonts/HandoTrial-Bold.woff') format('woff'),
        url('../assets/fonts/HandoTrial-Bold.ttf') format('truetype'),
        url('../assets/fonts/HandoTrial-Bold.svg#HandoTrial-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/HandoTrial-Black.eot');
    src: url('../assets/fonts/HandoTrial-Black.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/HandoTrial-Black.woff2') format('woff2'),
        url('../assets/fonts/HandoTrial-Black.woff') format('woff'),
        url('../assets/fonts/HandoTrial-Black.ttf') format('truetype'),
        url('../assets/fonts/HandoTrial-Black.svg#HandoTrial-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/HandoTrial-Regular.eot');
    src: url('../assets/fonts/HandoTrial-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/HandoTrial-Regular.woff2') format('woff2'),
        url('../assets/fonts/HandoTrial-Regular.woff') format('woff'),
        url('../assets/fonts/HandoTrial-Regular.ttf') format('truetype'),
        url('../assets/fonts/HandoTrial-Regular.svg#HandoTrial-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}*/

@font-face {
  font-family: 'Hando Trial';
  src: url('../assets/fonts/HandoTrial-Regular.eot');
  src: url('../assets/fonts/HandoTrial-Regular.eot') format('embedded-opentype'),
    url('../assets/fonts/HandoTrial-Regular.woff2') format('woff2'),
    url('../assets/fonts/HandoTrial-Regular.woff') format('woff'),
    url('../assets/fonts/HandoTrial-Regular.ttf') format('truetype'),
    url('../assets/fonts/HandoTrial-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hando Trial';
  src: url('../assets/fonts/HandoTrial-Light.eot');
  src: url('../assets/fonts/HandoTrial-Light.eot') format('embedded-opentype'),
    url('../assets/fonts/HandoTrial-Light.woff2') format('woff2'),
    url('../assets/fonts/HandoTrial-Light.woff') format('woff'),
    url('../assets/fonts/HandoTrial-Light.ttf') format('truetype'),
    url('../assets/fonts/HandoTrial-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hando Trial';
  src: url('../assets/fonts/HandoTrial-Bold.eot');
  src: url('../assets/fonts/HandoTrial-Bold.eot') format('embedded-opentype'),
    url('../assets/fonts/HandoTrial-Bold.woff2') format('woff2'),
    url('../assets/fonts/HandoTrial-Bold.woff') format('woff'),
    url('../assets/fonts/HandoTrial-Bold.ttf') format('truetype'),
    url('../assets/fonts/HandoTrial-Bold.svg') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 900;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

/*GLOBAL CSS DATE 29-06-2022*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px;
  padding: 0;
  font-family: "Hando Trial", sans-serif;
  color: #000;
}

h1 {
  font-size: 48px;
  font-weight: bold;
}

h2 {
  font-size: 40px;
  font-weight: bold;
}

h3 {
  font-size: 32px;
  font-weight: normal;
}

h4 {
  font-size: 24px;
  font-weight: normal;
}

h5 {
  font-size: 20px;
  line-height: 30px;
  font-weight: normal;
}

h6 {
  font-size: 16px;
  line-height: 30px;
  font-weight: normal;
}

p {
  margin: 0 0 30px;
  line-height: 30px;
  font-size: 16px;
  padding: 0;
  font-family: "Hando Trial", sans-serif;
  font-weight: normal;
}

.m20 {
  margin-bottom: 20px;
}

$black: #000;
$black2: rgb(0 0 0 / 60%);
$white: #fff;
$green: #3eab70;
$dark-green: #19401e;
$sky: #ecf7f1;
$sky2: #e8f5ee;
$sky3: #4eaffe;
$gray: #666666;
$gray2: #e0e0e0;
$red: #e4775a;
$font: "Hando Trial", sans-serif;
$bg-gray: #E5E5E5;

// .bg-dark-green {background: $dark-green;}
.sky {
  background: $bg-gray;
}

.white h1,
.white h2,
.white h3,
.white h4,
.white h5,
.white h6,
.white p {
  color: $white;
}

.green h1,
.green h2,
.green h3,
.green h4,
.green h5,
.green h6,
.green p {
  color: $green;
}

.gray h1,
.gray h2,
.gray h3,
.gray h4,
.gray h5,
.gray h6,
.gray p {
  color: $gray;
}

.nbtn {
  border: 0;
  display: inline-block;
  vertical-align: middle;
  background: $green;
  color: $white;
  padding: 15px;
  font-family: $font;
  font-weight: normal;
  font-size: 20px;
  line-height: 1;
  border-radius: 6px;
}

.nbtn:hover {
  background: $white;
  color: $black;
}

.nbtn-white {
  border: solid 2px $white;
  display: inline-block;
  vertical-align: middle;
  background: transparent;
  color: $white;
  padding: 15px;
  font-family: $font;
  font-weight: normal;
  font-size: 20px;
  line-height: 1;
  border-radius: 6px;
}

.nbtn-white:hover {
  background: $green;
  color: $white;
  border-color: $green;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    display: inline-block;
    font-family: $font;
  }
}

.disc-list ul li,
.angular-editor-wrapper ul li {
  padding-left: 15px;
  position: relative;
  display: block;
}

.disc-list ul li::before,
.angular-editor-wrapper ul li::before {
  position: absolute;
  left: 0;
  top: 11px;
  border-radius: 50%;
  content: "";
  width: 7px;
  height: 7px;
  background: #000;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*GLOBAL CSS DATE 29-06-2022*/

/*..............embedded fonts..............*/

/*..............custom fonts..............*/
/*..............suvo start..............*/
.search-suvo {
  font: 54px Arial, sans-serif;
  padding: 91px;
  text-align: center;
}

.search-tag-suvo {
  text-align: center;
}

.suvo-term strong {
  font-size: 26px;
}

/*..............suvo end..............*/

/*..............custom fonts..............*/

/*..............common styles..............*/
html,
body {
  margin: 0;
  padding: 0;
  font-family: "Hando Trial", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #000;
}

/*
h1, h2, h3, h4, h5, h6 {  margin:0 0 20px;line-height: 1.2 ; padding:0; font-family: 'Roboto'; font-weight: bold; color: #000; }
h1{ font-size:60px;  }
h2{ font-size:50px; }
h3{ font-size:40px;}
h4{font-size:30px;}
h5{ font-size:27px;}
h6{ font-size:20px;}
p{ margin:0 0 30px; line-height:26px; font-size: 16px; padding:0; font-family: 'Roboto';  font-weight: normal; }
*/
img {
  max-width: 100%;
}

a,
img {
  border: 0;
  text-decoration: none;
  outline: none;
}

a,
a:link,
a:visited,
a:focus,
a:hover {
  color: #3eab70;
  outline: none;
  text-decoration: none;
  transition: all 0.3s ease 0s;
}

a:hover {
  text-decoration: none;
}

input,
textarea,
select {
  font-family: "Hando Trial", sans-serif;
  font-weight: normal;
  font-size: 16px;
  outline: none;
}

input[type="submit"] {
  font-family: "Hando Trial", sans-serif;
  font-weight: normal;
  font-size: 16px;
  transition: all 0.3s ease 0s;
  outline: none;
}

select {
  background-image: url(../assets/images/Dropdown.png);
  appearance: none;
  -webkit-appearance: none;
  background-position: 97% center;
  background-repeat: no-repeat;
}

/* --- for placeholder color --- */
.form-control::-moz-placeholder {
  color: #a5a5a5;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #a5a5a5;
}

.form-control::-webkit-input-placeholder {
  color: #a5a5a5;
}

/* --- for placeholder color --- */

/* --- only use for wordpress (Image alignment) --- */
.alignleft,
.alignnone {
  float: left;
  margin: 0 15px 10px 0;
}

.alignright {
  float: right;
  margin: 0 0 10px 15px;
}

.aligncenter {
  margin: 10px auto;
  display: block;
}

/* --- only use for wordpress (Image alignment) --- */

/* --- Button Styles --- */
.btn {
  border: 0;
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  color: #000;
  padding: 14px 45px;
  font-family: "Hando Trial", sans-serif;
  font-weight: normal;
  font-size: 24px;
  line-height: 1;
  border-radius: 30px;
}

.btn:hover {
  background: #0f172a;
  color: #fff;
}

.btn.bdr-red {
  border: 2.5px solid #fc6748;
}

.btn.bdr-red:hover {
  background: #fc6748;
  color: #fff;
  border: 2.5px solid #fc6748;
}

.btn-red {
  background: #fc6748;
  color: #fff !important;
  padding: 16px 50px;
  display: inline-block;
  border-radius: 40px;
}

.btn-green {
  background: #3eab70;
  color: #fff !important;
  padding: 16px 50px;
  display: inline-block;
  font-family: "Hando Trial", sans-serif;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  border-radius: 10px;
}

.white-btn {
  background: #fff;
  color: #1b1f2d !important;
}

.btn.border {
  background: none;
  color: #1b1f2d !important;
  border: 1px solid #1b1f2d !important;
}

.btn.border:hover {
  background: #1b1f2d;
  color: #fff !important;
}

.btn.rounded {
  border-radius: 40px;
}

.btn-bdr-white {
  font-size: 30px;
  color: #fff !important;
  font-family: "Hando Trial", sans-serif;
  font-weight: normal;
  border: solid 2px #fff;
  padding: 16px 50px;
  border-radius: 50px;
  display: inline-block;
}

.btn-bdr-white:hover {
  background: #fff;
  color: #000 !important;
}

.btn.red {
  background: #fc6748;
  color: #fff !important;
}

.btn-bdr-green {
  font-size: 18px;
  font-family: "Hando Trial", sans-serif;
  font-weight: normal;
  color: #3eab70 !important;
  border: solid 1.3px #3eab70;
  padding: 11px 21px;
  border-radius: 4px;
  display: inline-block;
  background: #fff;
}

.btn-bdr-green:hover {
  color: #fff !important;
  background: #3eab70;
}

button.mat-primary {
  background: #3eab70 !important;
  padding: 20px 35px;
  line-height: 1;
  color: #fff !important;
  border-radius: 15px;
}

button.mat-button-base {
  //padding: 15px 35px;
  line-height: 1;
  border-radius: 30px;
}

/* --- Button Styles --- */

/* --- checkbox and radio button Styles --- */

/* --- checkbox and radio button Styles --- */

.common-padd {
  padding-top: 97px;
  padding-bottom: 97px;
}

.padd-btm {
  padding-bottom: 97px;
}

.padd-top {
  padding-top: 97px;
}

.common-padd-small {
  padding-top: 40px;
  padding-bottom: 40px;
}

.sm-padd-top {
  padding-top: 40px;
}

.sm-padd-btm {
  padding-bottom: 40px;
}

.common-padd-lt-rt {
  padding-left: 50px;
  padding-right: 50px;
}

.fifty {
  padding-bottom: 50px;
}
.ten {
  padding-bottom: 10px;
}

/*..............common styles..............*/

.search-tag-home .mat-form-field-wrapper,
.search-tag-home .mat-form-field-infix {
  height: auto !important;
  border: 0;
  padding: 0 !important;
  margin: 0 !important;
}

.search-tag-home .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.search-tag-home .form-control {
  border: 0;
  padding: 0;
  line-height: 1;
}

.search-tag-home .mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 !important;
}

.search-tag-home .mat-form-field-label-wrapper {
  top: 9px;
  left: 35px !important;
}

.whitebg {
  background: $white;
}

.e-notification .email-notification .up-notify {
  position: absolute;
  padding: 7px 0;
  left: 0;
  right: 0;
  top: -96px;
  background: #3eab70;
  width: 100%;
  text-align: center;
  color: #fff;
}

.email-notification a {
  color: #3eab70;
  padding-left: 15px;
}

header.main-header.e-notification {
  margin: 0 0 48px 0;
}

.e-notification .email-notification {
  position: absolute;
  padding: 11px 0;
  left: 0;
  right: 0;
  top: 71px;
  height: 48px;
  background: rgba(62, 171, 112, 0.1);
  text-align: center;
}

.email-notification.ng-star-inserted p {
  margin-bottom: 0 !important;
}

.loggedinn .max-width {
  max-width: 100%;
  padding: 0 2px;
}

.loggedinn .logo img {
  min-width: 53px;
}

.loggedinn .logo {
  margin-left: 15px;
}

.loggedinn .avatar-wrap {
  padding: 15px;
  background: rgb(62 171 112 / 10%);
}

.loggedinn.main-header {
  padding: 0 0;
}

.hdrrit .btn {
  background: transparent;
}

.hdrrit .btn:hover {
  background: #fc6748;
  color: #fff;
  border: 2.5px solid #fc6748;
}

/*for header css*/
header {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.logo img {
  min-width: 150px;
}

.hdr-main-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.main-header {
  background: $white;
  position: relative;
  z-index: 9999;
  padding: 0;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
}

.main-header.bdr-btm {
  border-bottom: 1px solid #f1f5f9;
}

.header-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-menu ul li {
  list-style: none;
  display: inline-block;
  font-family: "Hando Trial", sans-serif;
  padding: 10px 10px;
  font-weight: normal;
  text-transform: capitalize;
  position: relative;
}

.main-menu ul li a {
  display: flex;
  align-items: center;
  color: #1e293b;
  font-size: 24px;
  position: relative;
}

.main-menu ul li a img {
  filter: brightness(0);
  display: inline-block;
  vertical-align: top;
  padding-right: 3px;
}

.main-menu ul li.forEmployersbtn a {
  background: rgba(62, 171, 112, 0.23);
  border-radius: 30px;
  color: #3eab70;
}

.menutrigger {
  display: none;
  text-indent: -999px;
  background: url(../assets/images/menu.png) no-repeat center center;
  position: relative;
  width: 37px;
  height: 31px;
  float: right;
  cursor: pointer;
}

.menutrigger:after {
  position: absolute;
  top: -3px;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: #fff;
  opacity: 0.2;
  width: 37px;
  height: 31px;
  border-radius: 6px;
}

.menutrigger.menu_close {
  background: url("../assets/images/menu_close.png") no-repeat center center;
  height: 31px;
  width: 37px;
  position: fixed;
  right: 25px;
}

.hdrrit a.login {
  font-family: "Hando Trial", sans-serif;
  font-weight: normal;
  font-size: 24px;
  display: inline-block;
  padding-right: 70px;
}

.main-menu ul li.current-menu-item a:after,
.main-menu ul li:hover a:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -17px;
  content: "";
  width: 100%;
  height: 4px;
  background: #fc6748;
  animation: pulse 2s linear;
}

.loggedinn .main-menu ul li:hover a:after {
  bottom: -17px;
}

.loginys ul li.current-menu-item a:after,
.loginys ul li:hover a:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -23px;
  content: "";
  width: 100%;
  height: 4px;
  background: #fc6748;
  animation: pulse 2s linear;
}

@keyframes pulse {
  0% {
    width: 1%;
  }

  70% {
    width: 75%;
  }

  100% {
    width: 100%;
  }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3eab70;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #3eab70;
}

// This is only for the radio buttons on the modal popup 
.my-radio-group .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #494949 !important;
}

.my-radio-group .mat-radio-button.mat-accent .mat-radio-inner-circle,
.my-radio-group .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.my-radio-group .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.my-radio-group .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #494949 !important;
}

.white h1,
.white h2,
.white h3,
.white h4,
.white h5,
.white h6,
.white p {
  color: #fff;
}

// ul {
//   margin: 0;
//   padding: 0;
// }

div {
  position: relative;
}

.loginys {
  margin: 0;
  padding: 0;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;

    li {
      margin: 0 10px;
      position: relative;

      a {
        border: 0;
        font-family: "Hando Trial", sans-serif;
        color: #000;
        font-weight: normal;
        background: none;
        cursor: pointer;
        vertical-align: middle;
        display: inline-block;
        font-size: 20px;
        line-height: 1;

        img {
          filter: brightness(0);
          display: inline-block;
          vertical-align: top;
          padding-right: 3px;
        }
      }
    }
  }
}

.profile {
  position: relative;
  z-index: 9999;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      position: relative;
      display: block;

      a {
        padding: 12px 10px;
        color: #000;
        display: block;
      }

      a:hover {
        color: #3eab70;
      }
    }

    ul.sub-menu {
      display: none;
      position: absolute;
      top: 35px;
      right: 0;
      width: 175px;
      border-radius: 6px;
      background: #fff;
      box-shadow: 0 0 15px #ddd;

      a {
        padding: 5px 10px;
      }
    }

    li:hover ul.sub-menu {
      display: block;
    }
  }
}

.profile:after {
  content: "";
  position: absolute;
  right: -2px;
  top: 19px;
  border: 0.3em solid #000;
  border-color: #000 transparent transparent transparent;
  transition: 0.4s all ease-in-out;
}

.profile>ul>li {
  width: 115px;
  display: inline-block;
}

a.emailname {
  width: 100px;
  overflow: hidden;
  height: 35px;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.avatar-wrap .mat-form-field-infix {
  padding: 0;
}

.avatar-wrap {
  display: flex;
  align-items: center;
}

.avatar-pic img {
  width: 32px;
  height: 32px;
  min-width: 32px;
  object-fit: cover;
  border-radius: 50%;
}

.listwrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.listwrap h3 {
  margin-bottom: 0;
}

.listwrap a.btn-green {
  border-radius: 30px;
  font-size: 16px;
}

/*BODY SECTION CSS*/
.bgcolor {
  padding: 15px;
  background: #fffaea;
  border-radius: 15px;
}

.hotjobs .bgcolor {
  background: rgb(252 103 72 / 15%);
}

.bodyjobsearch-left .bodyjobsearch-share {
  width: 97px;
}

.bodyrow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.bodyjobsearch-desc {
  min-width: 71%;
  padding: 0 10px;
}

.jobtag {
  padding-top: 13px;
  color: #3eab70;
}

.jobtag .jobtag {
  padding-top: 0;
}

.title-wrap .jobtag {
  padding: 0 5px;
}

.title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bodyjobsearch-right h2,
.profilelogo *,
.date *,
.bodyjobsearch-right p {
  font-weight: normal;
  font-family: "Hando Trial", sans-serif;
}

.title-wrap h2 {
  font-size: 34px;
}

.profilelogo {
  display: flex;
  align-items: center;
}

.profilelogo h4 span {
  display: block;
}

.profilelogo h4 {
  margin-left: 15px;
  font-size: 16px;
}

hr {
  border-top: solid 1px rgb(191 191 191 / 40%);
  margin: 20px 0;
}

.date {
  font-size: 12px;
  color: rgb(0 0 0 / 60%);
}

.bodyjobsearch-right p {
  font-size: 14px;
  line-height: 17px;
  color: #000;
}

.green-text {
  font-weight: 700;
  font-family: "Hando Trial", sans-serif;
  color: #3eab70;
  font-size: 14px;
  line-height: 24px;
}

.bodyjobsearch-right h3 {
  font-weight: 500;
  font-size: 20px;
  font-family: "Hando Trial", sans-serif;
}

.bodyjobsearch-img {
  width: 60px;
  min-width: 60px;
}

.bodyjobsearch-img img {
  width: 60px;
}

.jobtag img {
  width: 26px !important;
  height: 26px !important;
  object-fit: cover !important;
}

.featured-jobs-list {
  border: solid 1px #f3f3f3;
  padding: 15px;
  border-radius: 30px;
}

.featured-jobs-list h3 {
  text-align: center;
  margin: 30px 0 !important;
  display: block;
}

/*HOME PAGE===================================*/
.qualified-info h2 {
  font-family: "Hando Trial", sans-serif;
  font-weight: bold;
}

.qualified-info h2 span {
  font-family: "Hando Trial", sans-serif;
  font-weight: normal;
  font-style: italic;
  font-size: 40px;
}

.green10 {
  background: rgb(62 171 112 / 10%);
  padding: 100px 0;
  border-radius: 20px;
}

.qualified-info {
  padding-left: 50px;
}

.img h4 {
  font-family: "Hando Trial", sans-serif;
  font-weight: normal;
}

/*SEARCH HOME*/
section.homesearch-area {
  background: #02441e;
  padding: 50px 0;
}

.homesearchform form {
  display: flex;
  align-items: center;
  background: #fff;
  margin-bottom: 30px;
  height: 100px;
  border-radius: 60px;
}

.homesearchform input.form-control {
  height: 70px;
  padding: 0 45px;
  background: transparent;
  border: 0;
}

.homesearchform button.submit {
  background: #fc6748;
  border: 0;
  color: #fff;
  height: 100px;
  padding: 0 6px;
  min-width: 265px;
  border-radius: 0 60px 60px 0;
  font-size: 29px;
  display: block;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

.form-control:focus {
  border: 0;
  outline: 0;
  box-shadow: 0 0 0;
}

.advancesearch ul li {
  display: inline-block;
}

.advancesearch ul li a {
  display: block;
  color: #fff;
  border: solid 2px #fff;
  border-radius: 30px;
  padding: 10px 20px;
  margin-right: 15px;
}

.advancesearch ul li:hover a {
  background: #fff;
  color: #000;
}

.customTooltip * {
  color: black;
  font-size: 16px;
  font-family: "Hando Trial", sans-serif;
}

.introjs-nextbutton {
  border-radius: 10px !important;
  background-color: #3eab70 !important;
  text-shadow: none !important;
  color: white !important;
  box-shadow: none !important;
}

.introjs-prevbutton {
  border-radius: 10px !important;
  text-shadow: none !important;
  box-shadow: none !important;
}


.customTooltip {
  border-radius: 20px;
}

.customTooltip .introjs-tooltip-title {
  color: #3eab70;
}
.customTooltip .introjs-arrow{
  display: none !important;
}

.introjs-helperLayer {
  border-radius: 5px !important;
  box-shadow: 0 3px 30px rgba(33,33,33,.3), rgba(32, 33, 33, 0.5) 0px 0px 0px 10000px !important;
}
.introjs-tooltip {
  background-color: #efefef;
  max-width: 400px;
  width: 325px;

}
.advancesearch h6 {
  font-family: "Hando Trial", sans-serif;
  display: inline-block;
  font-weight: 600;
  font-size: 23px;
  border-bottom: solid 1px #fff;
}

.introjs-progressbar{
  background-color: #3eab70;
}

/*SEARCH Resource*/
.homeResourceinfo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 80px;
  width: 50%;
}

.homeResource-banner .img img {
  width: 100%;
}

.homeResourceinfo .btn-wrap .btn-bdr-white {
  margin-right: 20px;
}

/*Jobs Categories*/
.jobsCategories-name a {
  background: #3eab701f;
  border-radius: 15px;
  display: block;
  margin: 10px 0;
  padding: 20px 30px;
  color: #303336;
  font-size: 24px;
}

.jobsCategories-name a:hover {
  background: #3eab70;
  color: #fff;
}

.bodyjobsearch-desc h5 span {
  color: #d1472e;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
}

/*Builder*/
.builder-area {
  position: relative;
  margin-top: 100px;
}

.builder-info {
  position: absolute;
  top: 80%;
  left: 9%;
  transform: translateY(-50%);
}

.builder-area h2 {
  font-family: "Hando Trial", sans-serif;
  font-weight: normal;
}

.builder-area h2 b {
  font-family: "Hando Trial", sans-serif;
  font-weight: bold;
  display: block;
}

.builder-area p {
  font-family: "Hando Trial", sans-serif;
  font-weight: 300;
  font-size: 27px;
  line-height: 40px;
}

.img img {
  width: 100%;
}

.homesearchform .fildwrap {
  position: relative;
  width: 43%;
}

.homesearchform .text-danger {
  position: absolute;
  left: 46px;
  top: 88px;
}

.homesearchform #homesearch:focus {
  background: transparent !important;
}

.mat-dialog-container {
  border-radius: 20px !important;
}

.verifyemployers-info .img img {
  width: 100%;
}

// .verifyemployers-info .img:after { background:rgb(253 244 230 / 90%); position: absolute;  top: 0; left: 0; bottom:0;  right: 0; content: ""; border-radius: 15px;}
.verifyemployers-desc {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}

.verifyemployers-desc h3 {
  font-weight: normal;
  font-family: "Hando Trial", sans-serif;
  color: #363636;
}

.verifyemployers-desc h5 {
  font-weight: bold;
  font-family: "Hando Trial", sans-serif;
  color: #363636;
}

.jobAlertFeature-area .hdr p {
  font-family: "Hando Trial", sans-serif;
  font-weight: 300;
  font-size: 27px;
  line-height: 40px;
  color: #000;
}

select.select-option {
  border: solid 0.5px #c1c1c1;
  padding: 15px;
  display: block;
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  border-radius: 6px;
  margin-bottom: 20px;
}

.alertFeature ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  padding: 15px 0;
}

.alertFeature h5 {
  background: #d8eee2;
  padding: 20px 65px;
  font-family: "Hando Trial", sans-serif;
  font-weight: normal;
  font-size: 24px;
}

.addspace {
  padding: 0 65px;
}

.jobAlertFeature-area {
  position: relative;
}

.jobAlertFeature-area:before {
  /*background: url(../assets/images/Landing-Page-job-Alerts.png) no-repeat; */
  position: absolute;
  top: 35%;
  left: 0;
  bottom: 0;
  content: "";
  right: 0;
  width: 33%;
  height: 350px;
  border-radius: 0 170px 170px 0;
}

.alert-bell {
  padding: 60px 0 0 60px;
}

.alert-bell h2 {
  color: #0a743a;
  margin-top: 30px;
}

.homesearchform #homesearch {
  background: url(../assets/images/Iconfeather-search.png) no-repeat 7% 18px;
  padding: 0 0 0 100px;
  border-right: solid 1px #a9a9a9;
  border-radius: 30px 0 0 30px;
}

/*HOME PAGE===================================*/

/*FILTER CSS*/
.filter-area .form-row:first-child,
.filter-area .form-row:nth-child(2) {
  width: 100%;
  padding: 13px 0;
}

.mat-form-field-infix.ng-tns-c91-1 {
  background: transparent !important;
}

.filter-area .form-row:first-child .mat-form-field-flex {
  border-right: solid 1px #999;
  border-radius: 50px 0 0 50px;
  padding: 24px 0 15px 65px;
  background: url(../assets/images/SearchKeywords.png) no-repeat !important;
  background-position: 25px 22px !important;
}

.filter-area .form-row:nth-child(2) .mat-form-field-flex {
  border-radius: 50px 0 0 50px;
}

.filter-area .form-row:nth-child(2) .mat-form-field-wrapper {
  border-radius: 6px 0 0 6px;
  padding: 4px 0 0 45px;
  background: #fff url(../assets/images/new-icon-1.png) no-repeat !important;
  background-position: 22px 22px !important;
}

.filter-area .form-row:last-child .mat-form-field-flex {
  border-radius: 50px 0 0 50px;
}

.filter-area .form-row:last-child {
  width: 24%;
  background: none;
  margin-right: 0;
}

.filter-area .form-row:last-child button.btn-red {
  border-radius: 0 6px 6px 0;
  border: 0;
  font-size: 16px;
  padding: 0 5px;
  display: block;
  width: 100%;
  height: 64px;
  margin: 0 0 0 0;
  outline: 0;
  max-width: 130px;
}

.filter-area button.btn-clear.btn-red {
  border: 0;
  margin: 20px 0 0 0;
}

.filter-area .form-row:nth-child(3) {
  max-width: 195px;
  padding-left: 40px;
  width: 25%;
  background: #fff url(../assets/images/new-icon-2.png) no-repeat !important;
  background-position: 20px 22px !important;
}

.select-wrap .select-row .mat-form-field-wrapper .mat-form-field-flex {
  border-radius: 10px;
  border: solid 1px rgb(0 0 0 / 20%);
  padding: 16px;
  height: 42px;
  display: flex;
  align-items: center;
  width: 200px;
}

.select-filter .select-row .mat-form-field-wrapper .mat-form-field-flex {
  border-radius: 10px;
  border: solid 1px rgb(0 0 0 / 20%);
  padding: 10px;
}
.select-filter .select-row .mat-form-field-wrapper {
  border-radius: 10px 10px 0 0;
  margin-bottom: 0 !important;
  padding-bottom: 0;
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: translateY(-50%);
  display: flex;
  // align-items: end;
}

.select-wrap .select-row .mat-form-field-wrapper {
  border-radius: 10px 10px 0 0;
  margin-bottom: 0 !important;
  padding-bottom: 0;
}

.mat-form-field-underline {
  opacity: 0 !important;
}

.form-wrap.top-fltr {
  max-width: 60%;
  justify-content: flex-end;
  align-items: center !important;
  display: flex;
  margin-bottom: 30px !important;
  margin: auto;
  border-radius: 6px;
  height: 64px;
}

.top-fltr span.text-danger {
  position: absolute;
  left: 60px;
  top: 100%;
}

.advance-search {
  max-width: 50%;
  text-align: right;
  margin: 20px auto 80px;
  font-size: 20px;
  text-decoration: underline;
}

.advance-search a {
  color: rgb(0 0 0 / 40%);
}

.form-wrap.top-fltr .form-row .mat-form-field-wrapper {
  padding-bottom: 0;
}

.filter-area .form-row {
  background: #ffffff;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}

.filter-area .form-row .form-control {
  width: 100%;
  padding: 8px 16px;
  border-radius: 4px;
  border: solid 1px #a2a2a2;
  font-size: 16px;
}

.form-wrap {
  display: flex;
  align-items: center;
}

.filter-area .form-row .topup {
  position: absolute;
  top: -8px;
  font-size: 12px;
  display: inline-block;
  background: #fff;
  padding: 0 5px;
  left: 15px;
}

// .select-wrap select {background: #F5F5F5;border-bottom:solid 1px rgb(0 0 0 / 38%) !important;border: 0;height: 51px;font-size: 15px;width: 100%;padding: 0 10px;padding-top: 0;}
.select-wrap .select-row {
  // vertical-align: middle;
  position: relative;
  display: block;
  margin: 0 5px 0 0;
}

.filter-area .select-row .btmup {
  position: absolute;
  top: 5px;
  font-size: 12px;
  display: inline-block;
  padding: 0 5px;
  left: 10px;
}

// label#mat-form-field-label-3 {
//   color: #000;
//   font-size: 15px;
// }

.select-wrap select {
  background: #f5f5f5;
  border-bottom: solid 0px rgb(0 0 0 / 38%) !important;
  border: 0;
  font-size: 15px;
  width: 100%;
  padding: 0 10px;
  padding-top: 0;
}

.select-wrap input {
  border-bottom: solid 0px rgb(0 0 0 / 38%) !important;
  border: 0;
  font-size: 15px;
  width: 100%;
  padding: 0 10px;
  padding-top: 0;
}

.choose-new-password mat-icon.mat-icon.notranslate.material-icons.mat-icon-no-color {
  position: absolute;
  right: 0;
  top: 7px;
}

.choose-new-password .form-group .form-control {
  padding-right: 32px;
}

mat-icon.mat-icon.notranslate.material-icons.mat-icon-no-color {
  color: #B8B8B8;
}

.custom-chip-set .mat-form-field-infix button.mat-chip-remove.mat-chip-trailing-icon.ng-star-inserted {
  border: 0 !important;
}

.custom-chip-set .mat-chip:hover .mat-chip-remove {
  opacity: 0.54 !important;
}

.custom-chip-set .mat-standard-chip .mat-chip-remove,
.custom-chip-set .mat-standard-chip .mat-chip-trailing-icon {
  margin-top: -5px !important;
  margin-right: 5px !important;
}

.custom-chip-set .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.1;
}

.custom-chip-set button.mat-chip-remove.mat-chip-trailing-icon .mat-icon {
  margin: -2px -12px;
}

/*SEARCH SECTION CSS*/
.bodyjobsearch-left .bodyjobsearch-box .bodyjobsearch-img img {
  width: 100%;
  height: 100%;
  object-position: left;
  object-fit: cover;
}

.bodyjobsearch-left .bodyjobsearch-img {
  width: 63px;
  height: 63px;
}

.bodyjobsearch-left .bgcolor {
  background: #f7f7f7;
  box-shadow: 0 0 0;
  border: 0.5px solid #A39696;
}

.bodyjobsearch-right .profilelogo h4 {
  margin-bottom: 0;
  color: var(--secondary-500, #666);
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-word;
}

.mnanage-bodyjobsearch-right .profilelogo h4 {
  margin-bottom: 0;
  color: var(--secondary-500, #666);
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-word;
}

.moreinfo-wrap {
  display: flex;
  justify-content: space-between;
}

button.mobilejob-back {
  display: none;
}

.bodyjobsearch-right h6 {
  font-weight: normal;
  font-family: "Hando Trial", sans-serif;
}

.jobcat-name,
.jobcat-name .jobtag {
  display: flex;
  align-items: center;
  justify-content: center;
}

.jobcat-name h6 {
  margin-bottom: 0;
}

.desc-half p {
  display: flex;
}

.desc-half p span.plft {
  min-width: 193px;
}

li.applied-btn a {
  background: rgb(0 0 0 / 10%);
  border-radius: 19px;
  display: inline-block;
  padding: 8px 23px;
  color: rgb(0 0 0 / 40%);
  margin-left: 5px;
}

.red {
  color: #ff4a4a;
}

.bodyjobsearch-left h4,
.job-manage-main h4 {
  padding: 10px 15px 10px 0;
  font-weight: normal;
  font-family: "Hando Trial", sans-serif;
}


.jobsearch-height {
  height: 745px;
  overflow: hidden;
  overflow-y: auto;
}

// .mat-select-arrow {
//   color: rgb(62 171 112) !important;
// }
//button.mat-focus-indicator {  padding: 0;}
.mat-dialog-container .btn-green {
  padding: 10px 50px;
  border: 0;
}

.mat-tab-label .mat-tab-label-content {
  align-items: baseline !important;
}

.bodyjobsearch-area {
  ::-webkit-scrollbar-track {
    background: $gray2 !important;
  }

  ::-webkit-scrollbar-thumb {
    background: $green !important;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #fff;
}

::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

div#cdk-overlay-0,
.cdk-overlay-pane {
  ::-webkit-scrollbar {
    width: 0;
  }
}

.cdk-overlay-container {
  z-index: 99999999999999999999999;
}

.banner-notification {
  // margin-top: 70px;
  background: #ebf6f0;
  color: #0e0e0e;
  text-align: center;
  padding: 15px 0;
}

.banner-notification-pending {
  margin-top: 70px;
  background: #eb9881;
  color: #0e0e0e;
  text-align: center;
  padding: 15px 0;
}

.banner-notification p {
  margin-bottom: 0;
}

.btn.btn-blue {
  background: #0d6efd;
  color: #fff;
}

.btn.btn-gray {
  background: gray;
  color: #fff;
}

.bodyjobsearch-box * {
  font-weight: normal;
  font-family: "Hando Trial", sans-serif;
}

.bodyjobsearch-box {
  display: flex;
  margin: 10px;
}

// .bodyjobsearch-box:hover,
// .success {
//   background: linear-gradient(90deg, rgba(62, 171, 112, 0.20) 41%, rgba(204.85, 203.50, 106.35, 0.20) 69%); 
//   box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.10); 
//   border-radius: 8px; 
//   border: 1px #3EAB70 solid;
//   // background: #e8f5ee !important;
//   // box-shadow: 0 0 15px rgb(167 144 144 / 70%);
// }

//.bodyjobsearch-share ul li:hover a, .bodyjobsearch-share ul li a.active {color: #3eab70;}
.bodyjobsearch-share ul {
  margin: 0;
  padding: 0;
}

.bodyjobsearch-share ul li {
  list-style: none;
  display: inline-block;
}

.bodyjobsearch-share ul li a {
  text-decoration: none;
  display: block;
  color: #000;
  font-size: 16px;
  padding-left: 15px;
}

// .bodyjobsearch-share ul li:first-child a {
//   padding: 0 5px 0 0;
// }
.bodyjobsearch-share ul li a i {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.bodyjobsearch-desc h5 {
  font-size: 20px;
  margin: 0 0 10px 0;
  line-height: 22px;
}

.bodyjobsearch-desc h6 {
  font-size: 14px;
  margin: 0 0 10px 0;
  line-height: 20px;
}

.bodyjobsearch-desc p {
  font-size: 12px;
  margin: 0 0 5px 0;
  color: rgb(0 0 0 / 60%);
}

.bgcolor {
  padding: 15px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 15px rgb(167 144 144 / 30%);
}

.bodyjobsearch-left .bodyjobsearch-share {
  width: 115px;
  text-align: right;
}

// .bodyjobsearch-share {width: 95px;}
.bodyjobsearch-desc {
  min-width: calc(100% - 170px);
  width: 100%;
  padding: 0 25px;
}

.bodyjobsearch-left {
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 5px;
}

.bodyjobsearch-right {
  height: 860px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 0;
  padding: 10px 40px;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
}

.title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// .title-wrap  .bodyjobsearch-share { width: 230px; }
.bodyjobsearch-right h2,
.profilelogo *,
.date *,
.bodyjobsearch-right p {
  font-weight: normal;
  font-family: "Hando Trial", sans-serif
}

.title-wrap h2 {
  font-size: 34px;
}

.bodyjobsearch-area .bodyjobsearch-right .profilelogo {
  display: flex;
  align-items: center;
  max-width: 45%;
  word-break: break-all;
}

.bookmarklisting .profilelogo img {
  width: 60px;
}

.profilelogo h4 span {
  display: block;
}

hr {
  border-top: solid 1px rgb(191 191 191 / 40%);
  margin: 20px 0;
}

.date {
  font-size: 12px;
  color: rgb(0 0 0 / 60%);
}

.bodyjobsearch-right p {
  font-size: 18px;
  line-height: 30px;
  color: rgb(0 0 0 / 50%);
}

.green-text {
  font-weight: normal;
  font-family: "Hando Trial", sans-serif;
  color: #3eab70;
  font-size: 14px;
  line-height: 24px;
}

.bodyjobsearch-right h3 {
  font-size: 20px;
  font-weight: normal;
  font-family: "Hando Trial", sans-serif;
}

.select-wrap .example-full-width {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0px 0px 0 30px;
}

.bodyjobsearch-left .bodyjobsearch-share ul li a {
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0;
}

.bodyjobsearch-left .bodyjobsearch-share ul li a i {
  padding-right: 5px;
}

.jobcat-name h6 span {
  color: #d1472e;
}

.profile-big-ttl {
  border-radius: 15px;
  padding: 50px;
  margin: 0 0 45px;

  h1 {
    font-size: 50px;
    position: relative;
    padding: 0 0 25px;

    &::after {
      content: "";
      background: url(../assets/images/star-bg-healdline-elemnt.png) no-repeat;
      width: 255px;
      height: 8px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #3eab70;
    border-color: #3eab70;
}

.page-link:hover {
    z-index: 2;
    color: #3eab70;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-link:focus {
    box-shadow: 0 0 0 .25rem rgba(1, 194, 130, 0.25);
}

.addalert .mat-form-field-wrapper,
.example-form .mat-form-field-wrapper,
.profile-middle-sec.appsettings-custom .mat-form-field-wrapper,
.forgotPassword .mat-form-field-wrapper {
  position: relative;
  border: solid 1px #c1c1c1;
  padding: 4px !important;
  margin-bottom: 15px;
  border-radius: 4px;
}

button.mat-chip-remove.mat-chip-trailing-icon .mat-icon {
  margin: -6px -12px;
}

.bodyjobsearch-left.applied-job-right-btm {
  margin-top: 30px;
}

//JOB SHARED MODAL
.googlelogo {
  display: flex;
  margin: 20px 0;
  align-items: center;
}

.googlelogo .img {
  margin-right: 10px;
}

.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}

.copylinkbx {
  background: #f2f2f2;
  padding: 5px 10px;
  border-radius: 6px;
  height: 46px;
}

.copylinkbx,
.copylinkbx p {
  display: flex;
  align-items: center;
}

.medialink ul,
.medialink ul li {
  margin: 0;
  padding: 0;
}

.medialink ul li {
  display: inline-block;
  align-items: center;
  width: 50%;
  justify-content: space-between;
}

.sameline {
  display: flex;
  align-items: center;
}

.medialink ul li button {
  box-shadow: 0 0 0 !important;
}

.copylinkbx button.sb-wrapper.sb-show-icon.sb-copy {
  box-shadow: 0 0 0;
  background: transparent;
  padding: 0 !important;
  min-width: auto;
}

.sharedjobmodal h5 {
  font-weight: normal;
  font-family: "Hando Trial", sans-serif;
}

.bookmarklisting {
  margin: 5px 0;
}

.profile-big-ttl.greentxt h1 {
  color: rgb(10 116 58 / 90%);
}

//job-management
.job-management .greenbg {
  background: rgb(62 171 112 / 10%);
  padding: 50px 0 0 0;
  margin-bottom: 30px;
  border-radius: 15px;
}

//job-management-main
.job-manage-main .mat-slide-toggle-bar {
  position: relative;
  width: 27px;
  height: 18px;
  flex-shrink: 0;
  border-radius: 16px;
}

.job-manage-main .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #3eab70 !important;
}

.job-manage-main .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffffff !important;
  margin-left: -2px !important;
}

.job-manage-main .mat-slide-toggle-thumb-container {
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  top: 3px;
  left: -2px;
  transform: translate3d(0, 0, 0);
  transition: all 80ms linear;
  transition-property: transform;
}

.job-manage-main .mat-slide-toggle-thumb {
  height: 12px;
  width: 12px;
  margin-left: 4px;
}

.job-manage-main .bodyjobsearch-box {
  margin: 10px 0;
}

.job-manage-main .bodyjobsearch-left .bgcolor {
  background: #fff;
}

.edit-deact .btn-bdr-green {
  border-radius: 40px;
  margin-right: 15px;
  padding: 14px 45px;
}

.job-manage-main .jobsearch-height,
.job-manage-main .bodyjobsearch-right {
  height: 830px;
}

.mystatistics-info {
  margin-bottom: 30px;
  height: 60px;
  background: #fffaea;
  border-radius: 6px;
  padding: 15px;
}

.mystatistics-info p {
  margin-bottom: 0;
}

.no-post-yet {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  width: 50%;
  text-align: center;
}

.dell-area {
  display: flex;
  align-items: center;
  height: 100%;
}

//jobseeker-profile modal
.custom-divider {
  text-align: center;
  position: relative;
}

.custom-divider:before {
  position: absolute;
  content: "";
  background: #ddd;
  width: 100%;
  height: 1px;
  left: 0;
  top: 19px;
}

.custom-divider span {
  display: inline-block;
  background: #fff;
  padding: 10px 15px;
  position: relative;
  z-index: 6;
}

.well-views .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #3eab7059 !important;
}

.well-views .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #3eab70 !important;
}

.mobile-first-profile-option-modal .btn-green {
  padding: 15px 40px;
}

.mobile-first-profile-option-modal br {
  display: none;
}

.mobile-first-profile-option-modal p {
  color: rgb(0 0 0 / 60%);
  line-height: 18px;
}

.mobile-first-profile-option-modal .wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

//jobseeker-profile-confirm
.profile-middle-sec .card h2 {
  display: flex;
  justify-content: space-between;
  font-size: 34px;
  align-items: center;
  font-weight: normal;
  font-family: "Hando Trial", sans-serif;
}

//.btn-green.wh-bg, .btn-green:hover { background: #fff !important; color: #3eab70 !important;}
.btn-green.wh-bg:hover {
  background: #3eab70 !important;
  color: #fff !important;
}

.card-mid-description {
  //border-bottom: 1px solid #ddd;
  padding: 15px 0;
}

.pro-img figure img {
  height: 70px;
  width: 70px;
  border-radius: 100%;
  object-fit: cover;
}

.profile-middle-sec .btn-green {
  text-decoration: none;
  color: #fff;
  border-radius: 3px;
  display: inline-block;
  background: #3eab70;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 10px 30px;
  border: 1px solid #3eab70;
  line-height: 20px;
}

.profile-middle-sec .card {
  box-shadow: 0 5px 12px rgba(167, 144, 144, 0.5);
  padding: 20px;
  border-radius: 5px;
  border: none;
  margin-bottom: 20px;
}

.pro-img figcaption strong {
  font-size: 20px;
  font-weight: 500;
}

.profile-middle-sec .card-mid.pro-img {
  display: flex;
  align-items: center;
}

.profile-middle-sec .card-mid.pro-img figure {
  margin: 0 15px 0 0;
}

.import-cv-top,
.profile-middle-sec {
  background: #f5f5f5;
  padding: 60px 0;
}

//Apply job
.file-upload-btn {
  border: 1px solid #3eab70 !important;
  box-shadow: none !important;
  color: #3eab70;
  width: 60%;
}

.file-upload-btn i {
  font-style: normal;
  border: 1px solid #3eab70;
  border-radius: 100%;
  font-size: 18px;
  line-height: 0;
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  top: -2px;
  margin-right: 4px;
  padding: 8px 0;
}

.close {
  cursor: pointer;
}

.example-form .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fff !important;
  border-radius: 5px !important;
}

.example-form .mat-form-field-underline {
  display: none;
}

.custom-saved-part .mat-form-field-underline {
  display: none;
}

.example-form .mat-form-field-wrapper {
  padding-bottom: 10px;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #3eab70;
}

.example-form .mat-flat-button {
  color: #3eab70;
}

.job-aleart-wrap button.mat-button-base,
.alertwrap button {
  padding: 15px 30px;
}

.text-right {
  text-align: right;
  margin-bottom: 0 !important;
}

.file-upload-btn {
  border: 1px solid #3eab70 !important;
  box-shadow: none !important;
  color: #3eab70;
  width: 60%;
}

.file-upload-btn i {
  font-style: normal;
  border: 1px solid #3eab70;
  border-radius: 100%;
  font-size: 18px;
  line-height: 0;
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  top: -2px;
  margin-right: 4px;
  padding: 8px 0;
}

.pl-0 {
  padding-left: 0;
}

.job-aleart-sec-new {
  padding: 50px 0;
  background: #fff;
}

.custom-file-upload {
  position: relative;
}

.custom-border-none .mat-form-field-underline {
  display: none;
}

.custom-file-upload input {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  opacity: 0;
}

.uploadText {
  position: absolute;
  top: -25px;
  left: 0;
  margin: 0 !important;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-inline-box !important;
  height: 22px;
  text-align: left;
}

.gray-btn {
  font-size: 14px;
  text-decoration: none;
  color: #fff !important;
  background: #aab6b0;
  border: 0;
  padding: 11px 25px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
}

.modal-dropdown .mat-form-field-type-mat-select .mat-form-field-infix {
  padding: 0;
}

.mat-form-field-type-mat-select .mat-form-field-wrapper {
  padding-bottom: 0;
  //border: solid 1px #9cd4b6;
  border-radius: 4px;
  margin-bottom: 18px;
}

.googlelogo .img img,
.title-wrap .profilelogo img {
  width: 60px;
  min-width: 60px;
  height: 60px;
  object-fit: cover;
}

//SAVE JOB
.savedselect .mat-form-field-type-mat-select {
  width: 220px;
}

.savedselect .mat-form-field-type-mat-select .mat-form-field-wrapper {
  border: solid 1px #c1c1c1;
  padding-bottom: 0;
  margin-bottom: 15px;
  border-radius: 4px;
  display: inline-block;
}

.savedselect .mat-button-base {
  padding: 15px 35px !important;
  display: inline-block;
}

.savedselect.custom-saved-part {
  display: flex;
  align-items: center;
}

.upload-cv-btn-wrap input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 140px;
  opacity: 0;
  cursor: pointer;
}

.import-cv-top-desc {
  padding-left: 80px;
}

.import-cv-top figure {
  margin-bottom: 0;
}

.import-cv-top {
  background: rgba(62, 171, 112, 0.1);
  border-radius: 15px;
  margin: 0 0 30px;
  padding: 0;
}

.import-cv-top .img img {
  width: 100%;
}

.import-cv-top h2 {
  position: relative;
}

.import-cv-top p {
  font-weight: normal;
  font-size: 27px;
  padding-right: 65px;
  line-height: 40px;
}

/*cv search page*/
.search-cvs-filter-area {
  margin-bottom: 50px;
}

.new-banner-style {
  position: relative;
}

.new-banner-style .img img {
  width: 100%;
}

.new-banner-style .lightbg {
  background: #fcfcf2;
  padding: 50px;
  border-radius: 15px;
}

.new-banner-style-info h1 {
  color: #0a743a;
  display: block;
  margin-bottom: 75px;
  position: relative;
}

.new-banner-style-info h1:before {
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 281px;
  height: 9px;
  content: "";
  background: #0a743a;
  border-radius: 15px;
}

.new-banner-style-info h1:after {
  position: absolute;
  bottom: -50px;
  left: 300px;
  width: 23px;
  height: 9px;
  content: "";
  background: #0a743a;
  border-radius: 15px;
}

.new-banner-style-info h4 {
  color: #000;
  font-weight: bold;
  line-height: 1.3;
}

.search-cvs-new-list h4,
.search-cvs-new-details h4 {
  margin: 20px 0 30px;
}

.search-cvs-new-list,
.search-cvs-new-details {
  box-shadow: 0 0 10px rgb(0 0 0 / 25%);
  padding: 15px;
  border-radius: 10px;
  position: relative;
  min-height: 895px;
  background: #fff;
}

.cvs-list-all-wrap a {
  display: flex;
  text-decoration: none;
  align-items: center;
  background: #f8f8f8;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 10px;
}

.cvs-list-all-wrap:hover a {
  background: #e3f3ea;
}

.cvs-list-all-wrap.dr-right a {
  justify-content: space-between;
}

.cvs-list-all-wrap.dr-right a .cvs-profile-info {
  flex: auto;
}

.cvs-profile-img {
  width: 60px;
  height: 60px;
}

.cvs-profile-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.cvs-profile-info {
  margin: 0 15px;
  width: 55%;
  word-break: break-word;
}

.cvs-profile-info h5 {
  text-decoration: none;
  color: #000;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: normal;
  font-family: "Hando Trial", sans-serif
}

.cvs-profile-info h5 span {
  display: block;
  font-size: 18px;
  padding: 15px 00 0;
}

.wheen-no-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 270px;
  text-align: center;
}

.wheen-no-content h4 {
  opacity: 0.4;
}

section.search-cvs-new-area {
  margin-bottom: 50px;
}

.search-cvs-new-list {
  height: 895px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.search-cvs-filter-area .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 6px !important;
  border: solid 3px #d6d6d6;
  background: #fff;
  height: 84px;
  padding: 0 15px 0 75px;
}

.search-cvs-filter-area input.mat-input-element {
  height: 50px;
}

.search-cvs-filter-area .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.8em;
  font-size: 20px !important;
}

.searchicon.mat-form-field-appearance-fill .mat-form-field-flex {
  background-image: url("../assets/images/feather-search.png") !important;
  background-repeat: no-repeat !important;
  background-position: 20px !important;
}

.locationicon.mat-form-field-appearance-fill .mat-form-field-flex {
  background-image: url("../assets/images/material-location-on.png") !important;
  background-repeat: no-repeat !important;
  background-position: 20px !important;
}

button.btn-submit-find {
  color: #fff;
  border: 0;
  width: 100%;
  height: 84px;
  border-radius: 6px;
  background: #3dbf89;
  font-size: 28px;
}

section.search-cvs-new-area .search-cvs-new-details .profile-middle-sec {
  background: transparent;
  padding: 30px 0;
}

button.btn-submit-find.cancle {
  background: #fc6748;
}

/*cv search page*/

//snackbar
.greenbg {
  background: #207419;
}

.redbg {
  background: #96312c;
}

.orangebg {
  background: #d17620;
}

.notifications-bar {
  display: flex;
  height: auto;
  background: $white;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  border: solid 1px #dbdbdb;
  border-radius: 6px;
}

.tickctn {
  display: flex;
  align-items: center;
}

.notifications-bar.succes .tickctn .tickctn-icon {
  background: #3eab70;
}

.notifications-bar.error .tickctn .tickctn-icon {
  background: #96312c;
}

.notifications-bar.skip .tickctn .tickctn-icon {
  background: #d17620;
}

.notifications-bar.skip .tickctn .tickctn-icon i {
  margin-top: -5px;
}

.tickctn .tickctn-icon {
  width: 45px;
  height: 45px;
  min-width: 45px;
  justify-content: center;
  align-items: center;
  line-height: 45px;
  text-align: center;
  color: $white;
  border-radius: 50%;
  display: flex;
  margin: 10px;
  font-size: 20px;
}

.tickctn-info p {
  margin: 0;
  color: $black;
  font-size: 16px;
  line-height: 20px;
}

.tickctn-right p a {
  font-weight: 500;
  color: #818181;
  text-decoration: none;
  padding: 0;
  font-size: 20px;
}

.tickctn-right span a {
  display: block;
  font-weight: 300;
  text-align: center;
  border-top: solid 1px #fff;
  padding-top: 5px;
}

.tickctn-right p {
  margin: 0;
  color: #fff;
  font-size: 16px;
  padding: 10px 0;
}

.notifications-bar.orangebg .tickctn-right p {
  padding: 22px 0;
}

.tickctn-right {
  margin-right: 20px;
}

//snackbar

.table-responsive::-webkit-scrollbar,
.mat-tab-body-content::-webkit-scrollbar {
  width: 5px;
}

.table-responsive::-webkit-scrollbar,
.mat-tab-body-content::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 4px;
}

main.new-screen.common-padd-small {
  padding-top: 75px;
  background-color: #fafafa;
}

.nameofheadoption {
  text-align: right;
  position: absolute;
  right: 0;
  z-index: 9999999999;
  top: 20px;
}

.mat-tab-body-content {
  margin-bottom: 15px;
}

.mat-tab-body-content {
  height: auto !important;
}

.emp-dashboard-box .mat-tab-body-content {
  overflow: hidden;
}

td.flex {
  display: flex;
}

//no job
.no-job-posted-yet {
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  max-width: 335px;
  margin: 200px auto 250px auto;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
}

/*for footer sections css*/

// .main-footer {
//   padding: 50px 0 0 0;
//   margin-top: 100px;
// }

ul.ftr-nav {
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
}

ul.ftr-nav li {
  width: 49%;
  display: inline-block;
  vertical-align: middle;
}

ul.ftr-nav li a {
  color: #000;
  opacity: 0.7;
  font-size: 21px;
  line-height: 28px;
  text-transform: capitalize;
  padding-bottom: 20px;
  display: inline-block;
}

ul.ftr-nav li span {
  background: #125bc9;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 9px;
  margin-left: 10px;
}

.social-media {
  margin: 30px 0 20px;
}

.social-media ul {
  margin: 0;
  padding: 0;
}

.social-media ul li {
  display: inline-block;
  text-align: center;
  margin-right: 40px;
}

.social-media ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.social-media ul li a:hover {
  color: #6bb88e;
}

.social-media2 {
  margin: 30px 0 0;
}

.social-media2 ul {
  margin: 0;
  padding: 0;
}

.social-media2 ul li {
  display: inline-block;
  text-align: center;
  margin-right: 15px;
}

.social-media2 ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.social-media2 ul li a:hover {
  color: #6bb88e;
}

.subscribe .invaild-div.ng-star-inserted {
  position: absolute;
  top: 100%;
}

.copyrights {
  background: #3eab70;
  text-align: right;
  margin-top: 50px;
}

.copyrights ul {
  margin: 0;
  padding: 0;
}

.copyrights ul li {
  display: inline-block;
}

.copyrights ul li a {
  display: inline-block;
  padding: 15px 0 15px 50px;
  color: #fff;
  font-size: 21px;
}

footer.main-footer * {
  font-family: "Hando Trial", sans-serif;
  font-weight: normal;
}

.ftr-left {
  background: #ebf7f0;
  padding: 50px;
  border-radius: 15px;
  margin-top: -100px;
  position: relative;
}

.ftr-left p {
  color: #000;
  opacity: 0.5;
  margin: 25px 0;
}

.ftr-left h4 {
  font-size: 31px;
  color: #000;
}

.addressinfo h4 {
  margin-left: 15px;
  font-size: 29px;
}

.addressinfo h4 span {
  display: block;
  font-size: 21px;
  opacity: 0.7;
}

.addressinfo {
  display: flex;
  align-items: baseline;
  margin-bottom: 75px;
}

.ulinks h4,
.subscribe h4 {
  margin-bottom: 50px;
  font-size: 29px;
  position: relative;
}

.ulinks h4:after {
  position: absolute;
  left: 0;
  bottom: -20px;
  content: "";
  background: url(../assets/images/double-line.png) no-repeat;
  width: 146px;
  height: 9px;
}

.subscribe h4:after {
  position: absolute;
  left: 0;
  bottom: -20px;
  content: "";
  background: url(../assets/images/double-line2.png) no-repeat;
  width: 122px;
  height: 9px;
}

// .main-footer .col-lg-8 {
//   padding-left: 115px;
// }
.subscribe p {
  color: #000;
  opacity: 0.5;
  font-size: 21px;
  line-height: 32px;
}

.subscribe form {
  display: flex;
}

.subscribe form button {
  background: #fc6748;
  border: 0;
  width: 100px;
  height: 65px;
  border-radius: 0 30px 30px 0;
}

.subscribe form input.form-control {
  border-radius: 30px 0 0 30px;
  padding: 15px 30px;
}

/*for footer sections css*/

.alertwrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.job-aleart-table tr th:last-child {
  text-align: right;
}

.custom-radio-button {
  display: block !important;
  margin-bottom: 10px;
}

.bodyjobsearch-area .bodyjobsearch-right ul {
  margin-left: 20px;
}

.companies-wp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.companies-wp form {
  display: flex;
  align-items: center;
}

.companies-wp form .btnrightadd {
  margin-left: 30px;
}

.messagepopupdas.change-status .angular-editor-wrapper.show-placeholder {
  margin-bottom: 20px;
}

// Login page

.auth-mainbox {
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 35px;
}

.log-lt {
  padding: 0 30px;

  .form-wrap {
    margin: 0 0 20px;
    display: block;

    .form-group {
      margin: 0 0 10px;
    }
  }
}

.mat-form-field {
  width: 100%;
  font-family: 'Hando Trial';

  .mat-form-field-flex {
    background: #fff !important;
  }
}

.mat-option {
  font-family: 'Hando Trial';
}

.mat-select-value {
  font-family: 'Hando Trial';
}

.bdrbtm .mat-form-field .mat-form-field-flex {
  background: #fff !important;
  border: solid 1px #acacac;
}

.nobdr .form-control {
  border-bottom: 0;
}

.security-links {

  // padding-top: 20px;
  .forgot-link {
    text-align: right;
  }
}

.or-section {
  margin: 0 0 15px;
  display: flex;
  justify-content: center;
  position: relative;

  &:before {
    content: "";
    background: #DBDBDB;
    height: 1px;
    position: absolute;
    top: 6px;
    right: 0;
    left: 0;
  }

  .or {
    background: #fff;
    padding: 0 20px;
    color: #000000;
    font-size: 10px;
  }
}

.login-options {
  margin: 0 0 30px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;

    li {
      margin: 0 10px;

      button {
        border: 0;
        background: none;
        cursor: pointer;
      }
    }
  }
}

input.checkbox-in {
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

.btm-swipe-link {
  text-align: center;
  color: #8f8f8f;
}

.btm-swipe-link p,
p.sub-heading,
span.checkmark,
.col.forgot-link {
  font-size: 22px;
  line-height: 32px;
}

.signup-option {
  text-align: center;
  color: #000;
  font-family: 'Hando Trial', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14.5px;
  margin-left: 24.5vw;
  margin-top: 10px;
}

.log-sldr-wrap {
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  overflow: hidden;

  .imgbox {
    /*padding: 0 0 75%;*/
    position: relative;
    margin: 30px 0;
    text-align: center;

    img {
      width: 241px;
      /* height: 100%; object-fit: cover; position: absolute; top: 0; right: 0; bottom: 0; left: 0;*/
    }
  }

  .info {
    text-align: center;
    padding: 30px;
  }
}

.sign-lt {
  padding: 30px 0 0;
}

.log-lt .mat-tab-label {
  width: 50%;
}

.btn-back {
  background: transparent;
  border-radius: 0;
  border: 0;
  padding: 0px 0px;
  color: #3eab70;
}

.form-control {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #acacac;
  padding-bottom: 12px;
}

.form-control:focus {
  border-bottom: 2px solid #3f51b5;
}

.form-group .form-control {
  margin-bottom: 10px;
}

.min-height-blank-page {
  min-height: 650px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.pass-notifctn-box {
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 65px;
  margin: 0 auto;
  max-width: 560px;

  h4 {
    padding: 0 0 15px;
  }
}

.custom-dot {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background: red;
  color: #fff;
  position: absolute;
  right: -14px;
}

.email-notification {
  position: absolute;
  padding: 11px 0;
  left: 0;
  right: 0;
  top: 71px;
  height: 48px;
  background: #ebf6f0;
  text-align: center;
  z-index: 100;
}

// 14-03-2022

.hdrrit {
  align-items: center;
  display: flex;
}

.addspace.no-select select.select-option {
  pointer-events: none;
}

.mat-calendar-controls button.mat-button-base {
  padding: 0;
}

.mat-datepicker-toggle button.mat-focus-indicator.mat-icon-button.mat-button-base {
  padding: 0;
}

// section.filter-area{ padding-top: 60px;}
.copylinkbx p {
  margin-bottom: 0;
}

.chat_ib p {
  overflow: hidden;
  height: 26px;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

button.mat-focus-indicator.file-upload-btn {
  padding: 15px 15px;
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: translateY(-130%);
}

.example-form.addalert {
  height: 320px;
  overflow-x: hidden;
  overflow-y: auto;
}

.btn-wrap.flex a.btn.sm.btn-green {
  padding: 10px 15px;
  margin: 5px 5px 20px 0;
}

.pro-top-hd .btn-green {
  text-decoration: none;
  color: #fff !important;
  border-radius: 3px;
  display: inline-block;
  background: #3eab70;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 10px 30px;
  border: 1px solid #3eab70;
  line-height: 20px;
}

.pro-top-hd .btn-green.wh-bg,
.pro-top-hd .btn-green:hover {
  background: #fff;
  color: #3eab70 !important;
}

.pro-top-hd .btn-green.wh-bg,
.pro-top-hd .btn-green:hover {
  background: #fff !important;
  color: #3eab70 !important;
}

.featured-jobs-area .featured-jobs-list {
  min-height: 684px;
}

.cdk-global-overlay-wrapper h4 {
  padding-right: 20px;
}

.close-icon a {
  display: inline-block;
  padding: 0 5px;
  cursor: pointer;
}

.close-icon.m20 {
  right: 0;
  top: 0;
}

li.applied-btn.green a {
  background: #3eab70;
  color: #fff;
}

li.applied-btn.red a {
  background: #fc6748;
  color: #fff;
}

// 14-03-2022
.info-list ul li {
  margin-bottom: 5px;
  display: block;
}

input#mat-input-2 {
  height: 20px;
}

.locationicon input#mat-input-2 {
  height: 20px;
}

section.filter-area .mat-icon.notranslate.material-icons.mat-icon-no-color {
  font-size: 0;
  color: #3eab70;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 0 0 20px !important;
  text-align: right;
  position: absolute;
  top: 8px;
  background: transparent;
}

.emp-dashboard-box input.mat-input-element {
  min-height: auto !important;
  width: 100% !important;
}

.angular-editor {
  width: 100% !important;
}

.sign-lt .mat-form-field-flex {
  border: solid 1px #ccc;
  width: 95%;
}

.sign-lt input[type="submit"] {
  width: 95%;
}

.createbookmark input.sendcreate {
  background: #3eab70;
  color: #fff !important;
  padding: 13px 40px;
  display: inline-block;
  font-family: 'Roboto"';
  font-weight: normal;
  border-radius: 6px;
  border: 0;
  margin-top: 20px;
}

.custom-select mat-form-field.mat-form-field {
  height: 43px;
}

.custom-select mat-form-field.mat-form-field .mat-form-field-flex {
  padding: 0 15px;
}

.custom-select mat-form-field.mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0;
  border: solid 1px #686868;
  border-radius: 4px;
}

.small-banner {
  margin: 45px 0;
}

.small-banner .img img {
  width: 100%;
}

.smallbannerAlerts {
  margin-bottom: 50px;
}

.smallbannerAlerts h3 {
  position: absolute;
  padding-bottom: 15px;
  top: 60%;
  left: 50px;
}

.smallbannerAlerts h3:before {
  content: "";
  background: url(/assets/images/star-bg-healdline-elemnt.png) no-repeat;
  background-size: 100%;
  width: 255px;
  height: 8px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.superadmin-flex {
  display: flex;
  align-items: center;
}

.superadmin-flex .mat-form-field-wrapper {
  padding-bottom: 0;
}

.select-wrap.superadmin-flex .example-full-width {
  margin: 0 20px 0 0;
}

.tab-content.select-wrap .example-full-width {
  margin-top: 0;
  margin-left: 2px;
}

.img.smbanner img {
  width: 100%;
  margin-bottom: 30px;
}

.smallbannerAlerts.white h3 {
  top: 40%;
}

input.featureJob {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: sub;
  color: green;
}

/* Customize the label (the container) */
.chk {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.chk input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: solid 1px #757575;
}

/* When the checkbox is checked, add a blue background */
.chk input:checked~.checkmark {
  background-color: #3eab70;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chk input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.chk .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.security-links .checkmark {
  position: relative;
  border: 0;
  color: #1A1A1A;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Hando Trial', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.3px;
}

.Verified-employers {
  background: #4caf50;
  text-align: center;
  padding: 15px;
}

.Verified-employers h4 {
  margin-bottom: 0;
  color: #fff;
  font-weight: 400;
}

.well-views table.table button.green-btn {
  font-size: 14px;
  text-decoration: none;
  color: #fff !important;
  background: #818784;
  border: 0;
  padding: 11px 25px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
}

.messagepopupdas textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: solid 1px #dee2e6;
}

a.btn-home {
  border: solid 1px #c1c1c1;
  display: block;
  text-align: center;
  padding: 15px;
  font-size: 24px;
  font-weight: 400;
  border-radius: 6px;
  color: #000;
  text-transform: capitalize;
}

.mobile-first-profile-option-modal {
  padding-bottom: 30px !important;
}

footer.main-footer .addressinfo h4 {
  font-size: 18px;
}

.tooltip.show {
  z-index: 999999999;
  left: 4% !important;
  top: 77px !important;
}

.tooltip.show .arrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
  transform: rotate(180deg);
}

.cdk-global-overlay-wrapper::-webkit-scrollbar * {
  display: none !important;
}

.companies-wp a.btn.border.position-button-export {
  border-radius: 6px;
  border-color: #3eab70 !important;
  padding: 9px 10px;
}

#tabs button.btn.btn-green,
#tabs button.btn {
  border-radius: 6px;
  padding: 16px 20px;
}

.select-row .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0;
  // border: 0;
}

.well-views table.table.table-hover tr th:first-child {
  width: 5%;
}

.well-views table.table.table-hover tr th:nth-child(2) {
  width: 7%;
}

.well-views table.table.table-hover tr th:nth-child(3) {
  width: 25%;
}

.well-views table.table.table-hover tr th:nth-child(4) {
  width: 10%;
}

.well-views table.table.table-hover tr th:nth-child(5) {
  width: 10%;
}

.well-views table.table.table-hover tr th:nth-child(6) {
  width: 10%;
}

.well-views table.table.table-hover tr th:nth-child(7) {
  width: 10%;
}

.well-views table.table.table-hover tr th:last-child {
  width: 10%;
}

/*
.succes, .error, .skip {  position: fixed !important; top: 40px !important;width: 390px; right: 0;}
.cdk-overlay-container, .cdk-global-overlay-wrapper {  height: auto !important;}
*/
.emp-dashboard-bnr img {
  width: 100%;
}

// form.example-form .example-full-width.email-fild .mat-form-field-wrapper {
//   margin-bottom: 0 !important;
// }

form.example-form .errors-text {
  min-height: 24px;
}

.well-views.dashboardtab.table-responsive table tr td.actionctn {
  min-width: 310px !important;
}

.max-width {
  max-width: 1700px;
}

/*Media Query css*/

// @media only screen and (min-width: 1300px) {
//   .container {
//     max-width: 1190px;
//   }
// }

@media only screen and (max-width: 1920px) {}

@media only screen and (max-width: 1640px) {
  .main-menu ul li a {
    font-size: 20px;
  }

  h1 {
    font-size: 45px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 22px;
  }

  h6 {
    font-size: 20px;
  }

  .btn-bdr-white {
    font-size: 20px;
  }

  .builder-area p,
  .jobAlertFeature-area .hdr p {
    font-size: 20px;
    line-height: 30px;
  }

  .jobsCategories-name a {
    font-size: 24px;
  }

  .form-wrap.top-fltr {
    max-width: 70%;
  }
}

@media only screen and (max-width: 1440px) {
  .well-views table.table.table-hover tr th:first-child {
    width: 10%;
  }
}

@media only screen and (max-width: 1366px) {
  .bodyjobsearch-right {
    padding: 10px 15px;
  }

  .bodyjobsearch-left .bodyjobsearch-share ul li a {
    font-size: 12px;
  }

  .bodyjobsearch-desc h5 {
    font-size: 16px;
  }

  .bodyjobsearch-desc h6 {
    font-size: 12px;
  }

  .bodyjobsearch-desc p {
    font-size: 10px;
    line-height: 1;
  }

  .googlelogo .img img,
  .title-wrap .profilelogo img {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }

  .bodyjobsearch-right .profilelogo h4 {
    font-size: 16px;
  }

  .jobcat-name h6,
  .title-wrap .jobtag {
    font-size: 14px;
  }

  .title-wrap .jobtag {
    display: flex;
    align-items: center;
  }

  .bodyjobsearch-share ul li a {
    font-size: 12px;
  }

  .title-wrap .bodyjobsearch-share {
    width: 190px;
  }

  .bodyjobsearch-right p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .bodyjobsearch-left .bodyjobsearch-box .bodyjobsearch-img img {
    width: 45px;
    height: 45px;
  }

  .bodyjobsearch-left .bodyjobsearch-img {
    width: 45px;
    min-width: 45px;
  }

  .jobtag-area .jobtag {
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .bodyjobsearch-left {
    padding: 10px;
  }

  .filter-area .form-row:first-child .mat-form-field-flex {
    padding: 5px 0 2px 65px;
    background-position: 24px 6px !important;
  }

  .filter-area .form-row:first-child,
  .filter-area .form-row:nth-child(2) {
    padding: 5px 0;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    // padding: 5px 5px 5px !important;
    font-size: 14px;
  }

  .cvs-profile-img,
  .pro-img figure img {
    width: 40px;
    height: 40px;
  }

  .cvs-profile-info h5 {
    font-size: 15px;
  }

  .cvs-profile-info h5 span {
    font-size: 14px;
    padding: 7px 0 0;
  }

  .cvs-profile-info {
    width: 70%;
  }

  .well-views table.table.table-hover tr th:nth-child(2) {
    width: 9%;
  }

  .well-views table.table.table-hover tr th:nth-child(5) {
    width: 15%;
  }

  .well-views table.table.table-hover tr th:nth-child(6) {
    width: 12%;
  }

  .well-views table.table.table-hover tr th,
  .well-views table.table.table-hover tr td {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1280px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 22px;
  }

  h6 {
    font-size: 18px;
  }

  .bodyjobsearch-desc {
    min-width: calc(100% - 200px);
  }

  .btn-red {
    padding: 15px 40px;
  }

  .btn {
    padding: 14px 40px;
    font-size: 16px;
  }

  .main-menu ul li a {
    font-size: 16px;
  }

  .bodyjobsearch-share ul li a {
    font-size: 14px;
  }

  .bodyjobsearch-right .profilelogo h4 {
    font-size: 18px;
  }

  .title-wrap .bodyjobsearch-share {
    width: 190px;
  }

  .bodyjobsearch-area .bodyjobsearch-right .profilelogo {
    max-width: 40%;
  }

  .hdrrit .btn {
    padding: 10px 30px;
  }

  .hdrrit a.login {
    font-size: 16px;
    padding-right: 50px;
  }

  .well-views table.table.table-hover {
    width: 1600px;
  }

  .well-views table.table.table-hover tr th {
    min-width: 186px;
  }

  .well-views .table.table {
    width: 1000px;
  }

  .well-views {
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 1199px) {
  .hdrrit a.login {
    font-size: 16px;
    padding-right: 10px;
  }

  .homesearchform button.submit {
    height: 70px;
    padding: 0 6px;
    min-width: 220px;
    border-radius: 0 60px 60px 0;
    font-size: 20px;
  }

  .homesearchform {
    height: 70px;
  }

  .homesearchform #homesearch {
    padding: 0 0 0 60px;
  }

  .btn-bdr-white {
    font-size: 16px;
    padding: 10px 20px;
  }

  .homeResourceinfo .btn-wrap .btn-bdr-white {
    margin-right: 10px;
  }

  .common-padd-lt-rt {
    padding-left: 15px;
    padding-right: 15px;
  }

  select.select-option {
    font-size: 16px;
  }

  .addspace {
    padding: 0 15px;
  }

  .alertFeature ul li {
    font-size: 16px;
  }

  .bodyjobsearch-desc h5 {
    font-size: 17px;
  }

  .bodyjobsearch-left {
    padding: 15px 0;
  }

  .bodyjobsearch-right .profilelogo h4 {
    font-size: 20px;
  }

  .jobcat-name img {
    width: 60px;
  }

  .title-wrap div {
    width: auto;
  }

  .cutom-wrap-for-job-search .col-lg-2 {
    width: 33.333%;
  }

  .form-wrap.top-fltr,
  .advance-search {
    max-width: 100%;
  }

  .bodyjobsearch-left .bodyjobsearch-share {
    width: 80px;
    min-width: 80px;
    margin-left: 15px;
  }

  .bodyjobsearch-desc {
    word-break: break-word;
  }

  .loginys ul li a span {
    display: block;
    font-size: 14px;
  }

  .loginys ul li a {
    text-align: center;
  }

  .headind-filter-btn a.btn-green-line {
    font-size: 14px;
    margin: 5px;
    padding: 5px 15px;
  }

  .homesearchform form {
    height: 70px;
  }

  .jobsCategories-name a {
    font-size: 16px;
  }

  .cvs-profile-img {
    width: 50px;
    height: 50px;
  }

  .cvs-profile-info h5 {
    font-size: 14px;
  }

  .cvs-profile-info h5 span {
    font-size: 11px;
    padding: 0;
    word-break: break-all;
  }

  .cvs-profile-info {
    margin: 0 5px;
    width: 65%;
    word-break: break-all;
  }

  .main-menu ul li {
    padding: 10px 10px;
  }

  .bodyjobsearch-left h4,
  .job-manage-main h4 {
    padding: 10px 15px;
  }

  .loginys ul li.current-menu-item a:after,
  .loginys ul li:hover a:after {
    bottom: -16px;
  }

  .mat-tab-body-content.ng-tns-c283-5.ng-trigger.ng-trigger-translateTab tr td:first-child {
    width: 12%;
  }

  .mat-tab-body-content.ng-tns-c283-5.ng-trigger.ng-trigger-translateTab tr td:nth-child(2) {
    width: 20%;
  }

  .mat-tab-body-content.ng-tns-c283-5.ng-trigger.ng-trigger-translateTab tr td:nth-child(3) {
    width: 15%;
  }

  .mat-tab-body-content.ng-tns-c283-5.ng-trigger.ng-trigger-translateTab tr td:nth-child(4) {
    width: 15%;
  }

  .mat-tab-body-content.ng-tns-c283-5.ng-trigger.ng-trigger-translateTab tr td:nth-child(5) {
    width: 20%;
  }

  td.tneme {
    width: 12%;
  }

  td.temail {
    width: 30%;
  }

  .incoming_msg {
    max-width: 78%;
  }

  .outgoing_msg {
    max-width: 78%;
  }

  .incoming_msg_img p.chat_img {
    width: 40px;
    height: 40px;
    min-width: 40px;
    font-size: 16px;
  }

  .sent_msg p {
    border-radius: 20px 20px 0 20px;
    font-size: 15px;
    line-height: 23px;
  }

  .received_withd_msg p {
    font-size: 15px;
    line-height: 23px;
  }

  .chat_ib h5 {
    font-size: 16px;
  }

  .chat_people .chat_img p.chat_img {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .chat_img {
    width: 45px;
    height: 45px;
  }
}

@media only screen and (min-width: 992px) {
  .nav_sec {
    display: block !important;
  }

  .nav_sec .hdrrit {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {
  .main-menu ul li {
    padding: 0 2px;
  }

  .main-menu ul li ul.sub-menu li {
    width: 24%;
    display: inline-block;
    border-bottom: 0;
    padding: 0;
  }

  .main-menu ul li:not(:last-child) {
    margin-right: 0;
  }

  .main-menu ul li a {
    display: inline-block;
    padding: 10px 4px;
    font-size: 13px;
  }

  .main-menu ul li.menu-item-has-children>a:after {
    display: none;
  }

  .main-menu ul li.menu-item-has-children:after {
    content: "\f107";
    font-size: 12px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #0f172a;
    right: 0;
    top: 9px;
    color: #fff;
    position: absolute;
    right: 0;
    top: 15px;
  }

  .main-menu ul li ul.sub-menu,
  .main-menu ul li:hover ul.sub-menu {
    display: block;
  }

  .main-menu ul li ul.sub-menu.active {
    display: block;
  }

  .main-menu ul li ul.sub-menu {
    display: block;
  }

  .main-menu ul li ul.sub-menu li a {
    color: #0f172a;
    font-size: 16px;
    padding: 15px 0;
    text-transform: capitalize;
  }

  .main-menu ul li ul.sub-menu {
    width: auto;
    transform: translateX(0);
    left: 0;
    padding: 0;
    border-top: 0;
    box-shadow: 0 0 0;
    position: relative;
    top: 0;
    z-index: 1;
  }

  .main-menu ul li ul.sub-menu li p,
  ul.sub-menu:after {
    display: none;
  }

  .loggedinn .main-menu ul li:hover a:after {
    bottom: 0;
  }

  .logo {
    width: 100px;
  }

  .loggedinn .logo {
    margin-left: 15px;
    width: auto;
  }

  .homeResourceinfo {
    left: 20px;
    width: 60%;
  }

  .homeResourceinfo br {
    display: none;
  }

  .jobsCategories-area .col-lg-4 {
    width: 50%;
  }

  .builder-info {
    padding-bottom: 50px;
    text-align: center;
  }

  .verifyemployers-info .img img {
    height: 118px;
    object-fit: cover;
  }

  .ftr-left {
    padding: 45px;
    margin: 0 0 20px 0;
    text-align: center;
  }

  .main-footer .col-lg-8 {
    // padding-left: 15px;
    text-align: center;
  }

  .ulinks h4:after,
  .subscribe h4:after {
    right: 0;
    margin: auto;
  }

  .copyrights {
    text-align: center;
  }

  // .main-footer {
  //   padding: 15px 0 0 0;
  //   margin-top: 50px;
  // }
  .addressinfo {
    margin-bottom: 25px;
    justify-content: center;
  }

  .filter-area .form-row:nth-child(3) {
    max-width: 100%;
  }

  .filter-area .form-row:last-child {
    width: auto;
  }

  .filter-area .form-row:nth-child(2) {
    width: 33%;
  }

  .form-wrap.top-fltr {
    justify-content: center;
  }

  //message
  .received_withd_msg .time_date {
    left: 0;
    bottom: -20px;
    width: 100px;
  }

  .sent_msg span.time_date {
    right: 0;
    bottom: -22px;
    margin-left: 0;
    width: 100px;
  }

  .received_msg {
    width: calc(100% - 40px);
  }

  .incoming_msg,
  .outgoing_msg {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .mesgs {
    width: 55%;
    min-height: 755px;
  }

  .inbox_people {
    width: 45%;
    min-height: 745px;
  }

  .over-fild {
    right: 65px;
    top: 13px;
  }

  .input_msg_write input {
    font-size: 15px;
    min-height: 45px;
    width: calc(100% - 61px);
    padding: 13px 100px 15px 30px;
    border: solid 1px #cecece;
  }

  .msg_send_btn {
    height: 50px;
    right: 0;
    top: 3px;
    width: 50px;
  }

  .over-fild .emoji {
    margin: 0 5px !important;
  }

  .chat_img {
    width: 45px;
    height: 45px;
  }

  .new-screen h5 {
    font-size: 15px;
  }

  .btn-green-line {
    padding: 10px 15px;
  }

  .nameofhead span {
    width: 50px;
    height: 50px;
  }

  .nameofhead h6 {
    margin-bottom: 0;
  }

  .chat_people .chat_img p.chat_img {
    width: 45px;
    height: 45px;
    font-size: 20px;
  }

  .nameofhead span p.chat_img {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }

  .nameofhead h6 {
    font-size: 16px;
    padding-left: 5px;
    height: 45px;
  }

  button.mat-button-base {
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .received_withd_msg p,
  .sent_msg p {
    font-size: 13px;
    line-height: 19px;
  }

  .nameofheadright {
    width: 120px;
  }

  .inbox_chat {
    height: 460px;
  }

  .msg_history {
    height: 570px;
  }

  .cvs-profile-info {
    width: calc(100% - 80px);
  }

  .filter-area .form-row:last-child button.btn-red {
    padding: 0 10px;
  }

  td.dell-edit button.mat-button-base {
    display: inline-block;
    padding: 10px 15px;
  }

  .job-manage-main .jobsearch-height,
  .job-manage-main .bodyjobsearch-right {
    height: auto;
    margin-bottom: 30px;
  }

  .main-menu ul li.current-menu-item a:after,
  .main-menu ul li:hover a:after {
    bottom: -10px;
  }

  .loggedinn .main-menu ul li.current-menu-item a:after,
  .loggedinn .main-menu ul li:hover a:after {
    bottom: -13px;
  }

  .chat_ib p {
    width: auto;
    clear: both;
  }

  .chat_ib h5 span {
    font-size: 13px;
    float: none;
    margin-bottom: 5px;
    display: block;
  }

  .mat-select-panel {
    min-width: 90% !important;
    margin: 0 0 0 52px !important;
  }

  .subscribe .invaild-div.ng-star-inserted {
    left: 0;
    right: 0;
    text-align: center;
  }

  .import-cv-top .img {
    margin-top: 50px;
  }

  .alertwrap p {
    display: flex;
  }

  .alertwrap p button {
    min-width: 100px;
  }

  .savedselect .mat-form-field-type-mat-select {
    margin-right: 10px;
  }

  .profilelogo .btn.btn-green {
    width: 180px !important;
    margin: 0 auto 20px;
  }

  .log-lt {
    padding: 0;
  }

  .auth-mainbox {
    padding: 20px;
  }

  .btm-swipe-link p,
  p.sub-heading,
  span.checkmark,
  .col.forgot-link {
    font-size: 16px;
    line-height: 26px;
  }

  input.checkbox-in {
    height: 13px;
    width: 13px;
  }

  .smallbannerAlerts h3:before {
    width: 150px;
  }

  .smallbannerAlerts .img img {
    width: 100%;
  }

  .loggedinn .logo img {
    min-width: auto;
    max-width: 150px;
  }

  .main-menu ul li a img {
    vertical-align: middle;
    width: 24px;
  }

  //MOBILE VIEW ON CLICK ADD CLASS & REMOVE CLASS

  .bodyjobsearch-right {
    display: none;
  }

  .mobile-view-parent {
    display: block;
    overflow-y: scroll;
    height: auto !important;
    position: fixed;
    padding-top: 30px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 9999;
  }

  .jobsearch-height {
    height: auto;
  }

  .cdk-overlay-container {
    z-index: 99999 !important;
  }

  button.mobilejob-back {
    display: block;
    background: transparent;
    border: 0;
    color: red;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 20px;
  }

  .search-cvs-new-list,
  .search-cvs-new-details {
    box-shadow: 0 0 0 !important;
  }

  //MOBILE VIEW ON CLICK ADD CLASS & REMOVE CLASS

  button.mobilejob-back {
    display: none;
    border: 0;
    z-index: 9999;
    background: transparent;
    position: absolute;
    font-size: 20px;
    color: #000;
    right: 4px;
    top: 10px;
    text-align: right;
  }

  section.bodyjobsearch-area .bodyjobsearch-right.mobile-view-parent {
    display: block;
    position: fixed;
    z-index: 9999;
    top: 0;
    height: 100%;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;
  }

  .bodyjobsearch-right.bodyjobsearch-right-shadow.ng-star-inserted {
    display: none;
  }

  .mobile-view-parent .mobilejob-back,
  .mobile-view-parent .search-cvs-new-details {
    display: block;
  }

  .search-cvs-new-details {
    display: none;
  }

  button.btn-submit-find {
    margin-bottom: 10px;
  }

  .search-cvs-new-list {
    height: auto;
    padding: 0;
  }

  .edit-deact .btn-bdr-green {
    margin: 15px 15px 0 0;
    padding: 7px 45px;
  }

  .select-wrap .select-row {
    margin: 0 5px 5px 0;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  .avatar-wrap {
    position: absolute;
    right: 0;
  }

  .loggedinn .avatar-wrap {
    top: 0;
    padding: 9px;
  }

  .new-banner-style .lightbg {
    padding: 20px;
    margin: 0 auto 30px;
  }

  .jobsCategories-area .col-lg-4 {
    width: 100%;
    text-align: center;
  }

  .bodyjobsearch-img img {
    width: 40px;
  }

  .bodyjobsearch-share ul li a i {
    font-size: 16px;
  }

  .bodyjobsearch-share ul li a {
    font-size: 0;
  }

  .bodyjobsearch-img {
    width: 40px;
    min-width: 40px;
  }

  .bodyjobsearch-share ul li {
    display: block;
    padding: 5px 0;
  }

  .featured-jobs-list {
    padding: 5px;
  }

  .common-padd-lt-rt {
    padding-left: 15px;
    padding-right: 15px;
  }

  .bodyjobsearch-desc {
    min-width: calc(100% - 60px);
  }

  .bodyjobsearch-desc h5 {
    font-size: 16px;
    margin: 0 0 4px 0;
  }

  .homeResourceinfo h2 {
    font-size: 20px;
  }

  .homeResource-banner .img img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .homeResourceinfo .btn-wrap .btn-bdr-white {
    margin: 0 0 10px;
    font-size: 14px;
    padding: 7px 20px;
  }

  .common-padd {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .padd-btm {
    padding-bottom: 45px;
  }

  .padd-top {
    padding-top: 45px;
  }

  .fifty {
    padding-bottom: 15px;
  }

  .import-cv-top {
    margin: 30px 0;
    padding: 20px;
  }

  .advancesearch ul li a {
    padding: 10px 10px;
    margin: 5px;
    font-size: 14px;
  }

  .homesearchform {
    display: block;
    align-items: center;
    background: transparent;
    height: auto;
  }

  .homesearchform .fildwrap {
    width: 100%;
  }

  .homesearchform .text-danger {
    position: relative;
    left: 0;
    top: 0;
  }

  .import-cv-top .img {
    margin-top: 30px;
  }

  .lt-info {
    margin-bottom: 20px;
  }

  .edt-hdng .btn.btn-green {
    margin-top: 15px;
  }

  .savedselect.custom-saved-part {
    display: block;
  }

  .btn-green {
    font-size: 15px;
    padding: 13px 15px;
    min-width: 135px;
  }

  .import-cv-top p {
    font-size: 16px;
    padding-right: 0;
    line-height: 24px;
  }

  .homesearchform #homesearch {
    background: #fff url("../assets/images/Iconfeather-search.png") no-repeat 2% 8px;
    padding: 0 0 0 50px;
    border-right: solid 1px #a9a9a9;
    border-radius: 0;
  }

  .homesearchform input.form-control {
    height: 50px;
    padding: 0 45px;
    background: #fff;
    border: 0;
    margin: 5px 0;
  }

  .homesearchform button.submit {
    height: 50px;
  }

  .bodyjobsearch-desc p {
    font-size: 10px;
  }

  .alert-bell {
    padding: 30px 20px;
    text-align: center;
    background: #ecf7f1;
  }

  .jobAlertFeature-area:before {
    display: none;
  }

  .copyrights ul li {
    display: block;
  }

  .copyrights ul li a {
    display: block;
    padding: 15px 0;
    color: #fff;
    font-size: 21px;
  }

  .social-media ul li {
    margin-right: 15px;
  }

  .hdr-main-wrap {
    display: block;
    text-align: center;
  }

  .profile-big-ttl h1 {
    font-size: 30px;
  }

  .profile-big-ttl h1::after {
    width: 147px;
    background-size: 100%;
  }

  .logo img {
    min-width: 180px;
  }

  .bodyjobsearch-area .bodyjobsearch-right .profilelogo {
    max-width: 100%;
  }

  .hdrrit {
    position: absolute;
    top: -10px;
    right: 0;
  }

  .hdrrit .btn.bdr-red {
    border: 2.5px solid #fc6748;
    padding: 8px 15px;
  }

  .nav_sec {
    padding-top: 0;
  }

  .main-menu ul li a {
    padding: 10px 4px;
    font-size: 14px;
    text-align: center;
  }

  .jobsCategories-name a {
    margin: 5px 0;
    font-size: 20px;
  }

  .addressinfo h4 span {
    font-size: 16px;
  }

  .addressinfo h4 {
    font-size: 22px;
  }

  .title-wrap .bodyjobsearch-share {
    width: 260px;
    margin: 10px auto 0 auto;
  }

  .cdk-overlay-pane {
    max-width: 90vw !important;
    width: 90vw !important;
    display: block;
    word-break: break-all;
  }

  .mobile-first-profile-option-modal {
    width: 100% !important;
  }

  .e-notification .email-notification {
    top: 119px;
  }

  .main-menu ul {
    margin: 0 0 20px;
  }

  .title-wrap {
    display: block;
  }

  .bodyjobsearch-share ul li {
    display: inline-block;
    padding: 5px 0;
  }

  .cutom-wrap-for-job-search .col-lg-2 {
    width: 50%;
  }

  .form-wrap.top-fltr {
    display: block;
    height: auto;
    border: 0;
  }

  .filter-area .form-row:first-child,
  .filter-area .form-row:nth-child(2) {
    padding: 0;
    width: 100%;
  }

  .filter-area .form-row:first-child .mat-form-field-flex {
    border-right: 0;
    padding: 0 0 0 65px;
    background-position: 25px 2px !important;
  }

  .filter-area .form-row:last-child {
    width: 100%;
  }

  .filter-area .form-row:last-child button.btn-red {
    max-width: 100%;
    border-radius: 50px;
    border: 0;
    padding: 0 45px;
    display: block;
    height: 55px;
    margin: 0 0 0 0;
  }

  .bodyjobsearch-share ul li a {
    padding-left: 5px;
  }

  .filter-area .form-row:nth-child(2) .mat-form-field-wrapper {
    background-position: 22px 18px !important;
  }

  .advance-search {
    margin: 20px auto 20px;
  }

  .mat-form-field {
    width: auto;
  }

  .main-menu ul li a img {
    display: block;
    text-align: center;
    margin: 0 auto;
    padding: 0;
    width: 24px;
  }

  .bodyjobsearch-left .bodyjobsearch-share ul li a {
    font-size: 0;
    padding: 0;
    text-align: center;
  }

  .bodyjobsearch-left .bodyjobsearch-share {
    width: 24px;
    min-width: 24px;
  }

  .bodyjobsearch-left .bodyjobsearch-desc {
    min-width: calc(100% - 105px);
  }

  .title-wrap .bodyjobsearch-share ul li a {
    font-size: 18px;
    margin-right: 15px;
  }

  .addalert .form-group label {
    display: block;
  }

  .addalert .mat-form-field {
    display: block;
  }

  .alertwrap {
    display: block;
    text-align: center;
  }

  table.table.job-aleart-table {
    min-width: 435px;
  }

  .profile-middle-sec figcaption {
    margin-bottom: 10px;
  }

  .profile-middle-sec .card h2 {
    display: block;
  }

  .profile-middle-sec .card {
    display: block;
    padding: 15px;
  }

  .search-cvs-new-details .container {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
  }

  .search-cvs-new-details {
    margin: 20px 0;
  }

  .pro-img figure img {
    height: 40px;
    width: 40px;
  }

  .search-cvs-filter-area .mat-form-field {
    width: 100%;
  }

  //Message
  .inbox_people,
  .mesgs {
    width: 100%;
  }

  .message-page-area {
    padding: 45px 0;
  }

  .message-hdr-top {
    padding: 5px 20px;
    border-bottom: solid 1px #c4c4c4;
    border-top: solid 1px #c4c4c4;
  }

  .mesgs-wrap {
    padding: 10px;
  }

  .msg_history {
    height: 545px;
  }

  .mesgs.dynamic-for-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 9999;
    display: block;
  }

  .mesgs {
    display: none;
  }

  .loggedinn .logo {
    margin: 5px;
    width: 53px;
  }

  .loginys ul li a span {
    font-size: 11px;
  }

  .loginys {
    padding: 8px 0 12px 0;
    background: #f3f3f3;
  }

  a.emailname {
    width: 104px;
  }

  .homesearchform form {
    display: block;
    background: transparent;
    height: auto;
    margin-bottom: 0;
  }

  .search-cvs-new-details {
    padding: 0;
  }

  .new-banner-style-info h1:before {
    bottom: -31px;
    width: 120px;
    height: 6px;
  }

  .new-banner-style-info h1:after {
    bottom: -31px;
    left: 147px;
    width: 23px;
    height: 6px;
  }

  search-cvs-filter-area .mat-form-field {
    width: 100%;
  }

  .mystatistics-info .col-lg-3.text-right {
    text-align: center;
  }

  .mystatistics-info {
    display: block;
    text-align: center;
  }

  .mystatistics-info p {
    margin-bottom: 20px;
  }

  .bodyjobsearch-left h4,
  .job-manage-main h4 {
    padding: 10px 15px;
  }

  .bodyjobsearch-area.job-manage-main .bodyjobsearch-left .bodyjobsearch-img {
    width: 45px;
    min-width: 45px;
  }

  .bodyjobsearch-area.job-manage-main .bodyjobsearch-left .bodyjobsearch-img img {
    width: 45px;
    height: 45px;
  }

  .bodyjobsearch-area.job-manage-main .bodyjobsearch-left .bodyjobsearch-share ul li a {
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .bodyjobsearch-area.job-manage-main .bodyjobsearch-left .bodyjobsearch-share {
    width: 105px;
    min-width: 105px;
    padding-right: 10px;
  }

  .bodyjobsearch-area.job-manage-main .bodyjobsearch-left .bodyjobsearch-desc {
    min-width: calc(100% - 150px);
  }

  .cdk-overlay-container {
    z-index: 99999 !important;
  }

  .mat-form-field {
    display: block !important;
  }

  .filter-area .mat-form-field {
    display: inline-block;
  }

  .mat-form-field-infix {
    width: 100% !important;
  }

  .savedselect .mat-form-field-type-mat-select .mat-form-field-wrapper {
    display: block;
  }

  .savedselect .mat-button-base {
    display: block;
    width: 100%;
  }

  .savedselect .mat-form-field-type-mat-select {
    width: 100%;
  }

  .cdk-global-overlay-wrapper .alertwrap p {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
  }

  .homeBanner-area.common-padd-lt-rt,
  .jobsCategories-area.common-padd-lt-rt,
  .featured-jobs-area.common-padd-lt-rt {
    padding-left: 0;
    padding-right: 0;
  }

  .featured-jobs-area .featured-jobs-list {
    margin-bottom: 20px;
  }

  .main-header {
    padding: 10px 10px 10px;
    border-bottom: solid 1px #e9ecef;
  }

  .loggedinn .main-menu ul li.current-menu-item a:after,
  .loggedinn .main-menu ul li:hover a:after {
    bottom: 0px;
  }

  .email-notification.ng-star-inserted p {
    font-size: 14px;
  }

  .loginys ul li.current-menu-item a:after,
  .loginys ul li:hover a:after {
    bottom: -11px;
  }

  .email-notification {
    padding: 10px 0;
    top: 35px;
    height: 43px;
  }

  .profile-wrap .edt-hdng {
    margin: 0 0 20px !important;
    display: block !important;
  }

  .prfl-userbox {
    padding: 30px 15px !important;
  }

  .pro-top-hd .btn-green {
    margin-bottom: 15px;
  }

  .form-wrap.top-fltr .form-row {
    margin-bottom: 20px;
  }

  td.dell-edit button.mat-button-base {
    display: inline-block;
    padding: 10px 10px;
    font-size: 12px;
  }

  td.dell-edit button {
    min-width: 80px !important;
  }

  .tab-content.py-3.px-3.px-sm-0 {
    padding: 20px 0 0 0 !important;
  }

  .btnrightadd .btn-set a {
    padding: 5px 15px !important;
    font-size: 14px !important;
    min-width: 54px;
    margin: 0 5px !important;
    display: flex;
    align-items: center;
  }

  .btnrightadd .btn-set {
    display: flex;
  }

  .nameofheadoption {
    top: 7px;
  }

  .inbox_people {
    min-height: auto;
  }

  .chat_ib p {
    width: auto;
  }

  div#cdk-overlay-1 .mat-dialog-container {
    overflow-y: scroll;
    height: 500px;
  }

  .angular-editor {
    width: 100% !important;
  }

  .tickctn-info p {
    font-size: 14px;
    line-height: 18px;
    padding-right: 15px;
    margin: 10px 0;
  }

  .tickctn-right p {
    font-size: 14px;
  }

  .smallbannerAlerts h3 {
    left: 10px;
    font-size: 20px;
    padding-bottom: 8px;
  }

  .smallbannerAlerts h3:before {
    width: 80px;
  }

  .emp-dashboard-bnr {
    height: 150px;
  }

  .emp-dashboard-bnr img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: right;
  }

  .builder-area .img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .builder-area .img {
    height: 180px;
  }

  .builder-area .btn.btn-red {
    padding: 5px 15px;
    font-size: 12px;
  }

  .builder-info {
    top: 88%;
    padding-bottom: 0px;
  }

  .small-banner .img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
  }

  .small-banner .img {
    height: 120px;
  }

  .import-cv-top-desc {
    padding-left: 15px;
  }

  .loggedinn .logo {
    min-height: 51px;
    padding-top: 10px;
  }

  .loggedinn .logo img {
    min-width: auto;
    max-width: 135px;
  }

  main.new-screen.common-padd-small {
    padding-top: 25px;
  }

  .social-media2 ul li {
    margin-right: 28px;
  }

  .btnwrap.text-right {
    display: flex;
  }

  .btnwrap.text-right a.btn {
    min-width: auto;
  }

  .cutom-wrap-for-job-search .col {
    width: 100%;
    flex: 0 0 100% !important;
  }

  .companies-wp {
    display: block;
  }

  .companies-wp a.btn.border {
    padding: 7px 30px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 375px) {
  .savedselect .mat-button-base {
    padding: 10px 15px !important;
    display: inline-block;
    margin-bottom: 15px;
  }

  .listwrap a.btn-green {
    font-size: 14px;
  }

  .jobsCategories-name a {
    font-size: 14px;
  }

  .profile-big-ttl {
    padding: 30px;
  }

  label {
    font-size: 13px;
  }

  .log-lt .form-wrap .form-group .row {
    display: block;
  }

  .security-links .forgot-link {
    text-align: left;
  }

  .msg_history {
    height: 470px;
  }

  .builder-area .img {
    height: 165px;
  }
}

@media only screen and (max-width: 360px) {
  .main-menu ul li a {
    padding: 10px 4px;
    font-size: 12px;
  }

  .loginys ul li a span {
    font-size: 10px;
  }

  .loginys ul li {
    margin: 0 4px;
  }

  .msg_history {
    height: 448px;
  }

  .builder-area .img {
    height: 144px;
  }
}

@media only screen and (max-width: 320px) {
  .msg_history {
    height: 370px;
  }
}

/*Media Query css*/
.cdk-overlay-container {
  z-index: 10000 !important;
}